/*
Template Name: Kiraric
Author: <a href="https://www.os-templates.com/">OS Templates</a>
Author URI: https://www.os-templates.com/
Licence: Free to use under our free template licence terms
Licence URI: https://www.os-templates.com/template-terms
File: Back to Top JS
*/

jQuery("#backtotop").click(function () {
  jQuery("body,html").animate(
    {
      scrollTop: 0,
    },
    600
  );
});
jQuery(window).scroll(function () {
  if (jQuery(window).scrollTop() > 150) {
    jQuery("#backtotop").addClass("visible");
  } else {
    jQuery("#backtotop").removeClass("visible");
  }
});

document.addEventListener("DOMContentLoaded", function () {
  if (
    window.location.pathname === "/" ||
    window.location.pathname === "/index.html"
  ) {
    var logo = document.querySelector(".logo");
    if (window.innerWidth <= 600) {
      logo.style.display = "block";
    }
  }
});
